<template>
    <div class="footer">
        <div class="inner-footer">
            <div class="left">
                <p>
                    <a href="https://www.tsinghua.edu.cn/publish/thu2018/index.html" target="_blank" >清华大学</a>
                </p>
                <p>
                    <a href="http://life.tsinghua.edu.cn/" target="_blank" >清华大学生命科学学院</a>
                </p>
                <p>
                    <a href="http://www.cls.edu.cn/" target="_blank" >生命科学联合中心</a>
                </p>
                <p>
                    <a href="http://www.icsb.tsinghua.edu.cn/" target="_blank" >结构生物学高精尖创新中心</a>
                </p>
                <p>
                    <a href="http://www.moebioinfo.tsinghua.edu.cn/" target="_blank" >生物信息学教育部重点实验室</a>
                </p>
            </div>
            <div class="right">
                Copyright © 2024 张强锋实验室
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'dheader',
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.footer {
    background: #303030;

    .inner-footer {
        height: 19rem;
        color: #fff;
        width: 79%;
        max-width: 1200px;
        min-width: 900px;
        margin: 0 auto;
        text-align: left;
        font-size: 0;

        .left, .right {
            display: inline-block;
            font-size: 1.1rem;
            color: #fff;
            vertical-align: middle;
        }

        .left {
            width: 80%;
            box-sizing: border-box;
            padding: 2rem 0 1rem 0;

            p {
                line-height: 3rem;
                margin: 0;

                a {
                    color: #fff;
                    margin-right: 3rem;
                    display: inline-block;
                    min-width: 9rem;
                    text-decoration: none;
                }

                a:hover {
                    color: #ADD2FF;
                }
            }
        }

        .right {
            text-align: center;
            width: 20%;
        }
    }
}
</style>
