import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/contact',
      name: 'contact',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Contact.vue')
    },
    {
      path: '/xiuxianhuodong',
      name: 'xiuxianhuodong',
      component: () => import(/* webpackChunkName: "about" */ './views/xiuxianhuodong.vue')
    },
    {
      path: '/xiuxianhuodongdetail',
      name: 'xiuxianhuodongdetail',
      component: () => import(/* webpackChunkName: "about" */ './views/xiuxianDetail.vue')
    },
    {
      path: '/publication',
      name: 'publication',
      component: () => import(/* webpackChunkName: "about" */ './views/publication.vue')
    },
    {
      path: '/resources',
      name: 'resources',
      component: () => import(/* webpackChunkName: "about" */ './views/resources.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import(/* webpackChunkName: "about" */ './views/news.vue')
    },
    {
      path: '/newdetail',
      name: 'newdetail',
      component: () => import(/* webpackChunkName: "about" */ './views/newDetail.vue')
    },
    {
      path: '/course',
      name: 'course',
      component: () => import(/* webpackChunkName: "about" */ './views/course.vue')
    },
    {
      path: '/research',
      name: 'research',
      component: () => import(/* webpackChunkName: "about" */ './views/research.vue')
    },
    {
      path: '/team',
      name: 'team',
      component: () => import(/* webpackChunkName: "about" */ './views/team.vue')
    }
  ]
})
