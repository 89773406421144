<template>
  <div id="app">
    <!--<t-header-top/>-->
    <t-header/>
    <transition name="fade">
      <div class="content">
          <router-view/>
      </div>
    </transition>
    <t-footer></t-footer>
  </div>
</template>

<script>
import THeader from './components/THeader'
import TFooter from './components/TFooter'


export default {
  name: 'App',
  data () {
    return {
      isShowHeader: true,
      isHeader1: true
    }
  },
  components: {
    THeader,
    TFooter
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll, false)
  },
  computed: {
    path () {
      return this.$route.path
    }
  },
  watch: {
    path () {
      this.changeHeader()
    }
  },
  mounted () {
    this.changeHeader()
  },
  methods: {
    handleScroll () {
      const height = document.getElementById('index-face').clientHeight
      const heightTop = document.documentElement.scrollTop || document.body.scrollTop
      if (heightTop > (height - 70)) {
        this.isShowHeader = false
      } else {
        this.isShowHeader = true
      }
    },
    changeHeader () {
      if (this.$route.path !== '/') {
        this.isHeader1 = false
        // window.removeEventListener('scroll', this.handleScroll, false)
      } else {
        this.isHeader1 = true
        // window.addEventListener('scroll', this.handleScroll, false)
      }
    }
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  font-size: 10px;
}

@font-face{
  font-family: 'PingFang SC';
  src : url('./font/PingFang-regular.ttf');
}

#app {
  font-family: 'PingFang SC', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.content {
    margin-top: 10.6rem;
}

@media (min-width: 768px) {
    html {
      font-size: 10px;
    }
}
@media (min-width: 1500px) {
  html {
    font-size: 11px;
  }
}
@media (min-width: 1920px) {
  html {
    font-size: 12px;
  }
}
</style>
