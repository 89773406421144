<template>
  <div class="xiuxianhuodong">
    <img alt="image" src="../assets/home/banner.png" class="hezhao">
    <content-box>
      <!--<p class="about-title">-->
        <!--<img src="../assets/home/shiyanshi-icon.png" alt="实验室简介">-->
        <!--<span>{{$t('home').title.title}}</span>-->
      <!--</p>-->
      <p class="about-disc">
        {{$t('home').content[0].disc}}
      </p>
      <p class="about-disc margin-bottom">
        {{$t('home').content[1].disc}}
      </p>
      <p class="about-disc no-indent no-margin" v-for="item in $t('home').content[2].disc" :key="item">
        {{item}}
      </p>
    </content-box>
  </div>
</template>

<script>
  // @ is an alias to /src
  import ContentBox from '@/components/ContentBox.vue'

  export default {
    name: 'news',
    components: {
      ContentBox
    },
    data () {
      return {
        activeIndex: 0,
        aboutUs: [
          {
            img: require('../assets/home/shiyanshi.png'),
            icon: require('../assets/home/shiyanshi-icon.png'),
            title: '实验室简介',
            simpleIntro: '我们实验室隶属于清华大学生命科学学院，同时也是清华大学－北京大学生命联合中心，清华大学结构生物学高精尖创新中心，清华大学教育部生物信息实验室，以及清华大学合成和系统生物学中心的一部分。',
            disc: '我们实验室隶属于清华大学生命科学学院，同时也是清华大学－北京大学生命联合中心，清华大学结构生物学高精尖创新中心，清华大学教育部生物信息实验室，以及清华大学合成和系统生物学中心的一部分。'
          },
          {
            img: require('../assets/home/yanjiufangxiang.png'),
            icon: require('../assets/home/fangxiang-icon.png'),
            title: '研究方向',
            pullDown: true,
            simpleIntro: '我们的研究方向是结构生物学、基因组学、机器学习和大数据分析等多学科综合交叉领域。我们主要的研究兴趣是发展和使用计算和实验相结合的方法，阐释生物大分子（如蛋白、RNA、DNA）结构...',
            disc: '我们的研究方向是结构生物学、基因组学、机器学习和大数据分析等多学科综合交叉领域。我们主要的研究兴趣是发展和使用计算和实验相结合的方法，阐释生物大分子（如蛋白、RNA、DNA）结构和功能关系，重建其相互作用网络，以及结合结构生物学和系统生物学的手段，发掘与蛋白及RNA结构变化，以及大分子相互作用网络异常相关的复杂疾病（包括癌症和传染病）的机理和可能的治疗手段。我们拥有独特的蛋白和RNA结构建模和基于下一代测序的RNA结构测量、和高通量RNA－蛋白相互作用检测的技术，以及强大的计算和实验平台来推进我们的研究。'
          },
          {
            img: require('../assets/home/xingqu.png'),
            icon: require('../assets/home/xingqu-icon.png'),
            title: '主要研究兴趣',
            pullDown: true,
            simpleIntro: '1，解析生物大分子结构，特别是使用高通量深度测序的手段来探测和使用机器学习方法预测和建模RNA二级结构；并在此基础上阐释结构和功能关系，特别是发展蛋白质功能位点，RNA功能模体（motif)...',
            disc: ['1，解析生物大分子结构，特别是使用高通量深度测序的手段来探测和使用机器学习方法预测和建模RNA二级结构；并在此基础上阐释结构和功能关系，特别是发展蛋白质功能位点，RNA功能模体（motif）等有效预测或发现的机器学习方法；','2，发展基于高通量实验和结构建模的方法重建生物大分子如蛋白-蛋白、RNA-RNA、以及蛋白-RNA相互作用网络；', '3，特别的，针对长非编码RNA（lncRNA），结合序列和结构分析研究其系统进化和功能分类；', '4，结合RNA和蛋白的结构和分子相互作用网络，研究RNA病毒入侵细胞以及引起的传染病的分子机制和可能的治疗手段；', '5，发掘基因组突变导致蛋白及RNA折叠错误以及互作网络失调而引起疾病的机理，以及结合结构和网络发展对复杂疾病包括癌症的诊断、预后以及可能的治疗手段。']
          }
        ]
      }
    },
    mounted () {
      console.log(this._i18n.locale)
      window.scrollTo(0, 0)
    },
    methods: {
      changeTab (index) {
        this.activeIndex = index
      }
    }
  }
</script>

<style scoped>
  .xiuxianhuodong {
    width: 100%;
    font-size: 0;
  }

  .hezhao {
    width: 100%;
  }

  .back-white {
    background: #fff;
  }

  .about-title {
    font-size: 3rem;
    font-weight: bold;
    color: #484848;
    text-align: center;
    margin: 3rem 0 1rem 0;
    text-align: center;
  }

  .about-title img, .about-title span {
    vertical-align: middle;
    font-size: 2.6rem;
    color: #484848;
    font-weight: 500;
  }

  .about-title span {
    margin-left: 2rem;
  }

  .about-disc {
    font-size: 1.3rem;
    color: #717171;
    margin: 1.7rem 0 0 0;
    text-indent: 2em;
    text-align: left;
  }

  .no-indent {
    text-indent: 0;
  }

  .intro-item {
    width: 32.5%;
    display: inline-block;
    margin-right: 0.7%;
    vertical-align: top;
  }

  .no-margin {
    margin-top: 0;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }
</style>
