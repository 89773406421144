import cn from './cn'
import en from './en'

const langData = {
  en: en,
  cn: cn
}


export default langData
