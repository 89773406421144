const cn = {
  headerData: [
    {
      title: '新闻',
      url: '/news',
      children: []
    },
    {
      title: '团队成员',
      url: '/team',
    },
    {
      title: '研究内容',
      url: '/research',
    },
    {
      title: '研究资源',
      url: '/resources',
    },
    {
      title: '教学课程',
      url: '/course',
    },
    {
      title: '文章发表',
      url: '/publication',
    },
    {
      title: '休闲活动',
      url: '/xiuxianhuodong',
    },
    {
      title: '联系我们',
      url: '/contact',
    }
  ],
  research: {
    intro: {
      title: '研究内容',
      content: ['技术驱动科学发展。随着高通量的下一代测序、高精度定量质谱、高分辨率冷冻电镜和分子影像等新技术进步和应用，生物学研究进入了以发现和解读全基因组功能元件，揭示超复合物和复杂分子机器的结构，以及从全系统组件相互作用和定量水平来理解基因变化和疾病关系为主要目标的后基因组时代。这些“新”生物学的发展为我们解决传统问题提供新的视角和工具，更将我们对生物系统复杂性的认识提高到了一个新的水平，但同时也提出了更多新的更具挑战性的问题。',
        '我们意识到了这些问题、挑战和机会。我们也目睹了这些高通量、高精度、高分辨率的新技术已经和正在积累了大量的数据。我们预见“新”生物学的发展将越来越依赖于高性能计算和大数据分析等技术，来从前所未有的深度、广度和高度来研究生物问题。我们认为由于生物系统极其复杂，随着数据的积累，机器智能将在研究生物问题中扮演越来越重要的角色。',
      '我们实验室实践这个研究思路的领域是新兴的结构系统生物学。实验室将综合结构生物学、基因组学、机器学习和大数据分析等诸多研究手段来研究重大和前沿的生物学问题。我们以计算特别是机器学习为核心手段，并通过特有的实验平台来探索我们的猜想。特别的，实验室也对发展和创造新的计算和实验技术充满兴趣。']
    },
    others: [
      {
        title: '1、生物大分子特别是RNA结构及其和功能关系',
        content: ['序列决定结构，结构决定功能。如何从生物大分子（蛋白、RNA以及DNA）序列出发准确预测其结构，如何比较和分析结构空间中各器件的关系，以及如何从结构出发预测其功能是结构信息生物学的中心课题之一。', '具备阻断逆转录过程能力的结构特异性的化学修饰可以用来探测 RNA 结构。在对RNA进行修饰后，再加入逆转录酶，然后通过测序得到逆转录停止的位点，即修饰位点。因为该修饰是结构特异的，我们可以据此推断修饰位点的结构特性。我们在Stanford的Howard Chang实验室发展了一种新的细胞内全转录组RNA结构的测定方法（icSHAPE：in vivo click SHAPE）。该方法使用了一个新合成的、细胞渗透性小分子NAI-N3。该分子可以根据糖环上2’OH 基团所在的结构环境有选择地修饰 RNA。并且由于它具有一个附加的叠氮化侧链，修改后的RNA 分子可以通过化学反应连接一个手柄并进行纯化，从而可以极大减少测序背景和提高结构探测的准确性。我们在小鼠胚胎干细胞的初步结果证明新方法出色的信号噪声比和结构探测的准确和有效性，并从全转录组水平揭示了RNA结构和功能的关系。', '我们实验室将发展基于in vivo实验信息来预测和建模细胞内RNA分子结构的更有效的算法和软件。目前的预测方法大多基于模拟折叠，无法得到细胞内的结构状态。我们的研究将揭示RNA分子在执行功能时候的结构，以及不同状态下的构像变化。实验室将在此基础上分析蛋白、RNA等结构之间的关系以及结构空间的属性。特别的，我们将发展蛋白质功能位点、RNA功能模体（motif）等有效预测或发现方法，以进一步阐释生物大分子结构决定功能的原则和规律。']
      },
      {
        title: '2、生物大分子相互作用网络',
        content: ['生物大分子（蛋白质、 RNA以及DNA 分子）的功能是通过与其它大分子相互作用来实现的。这些相互作用的模式具有结构上的保守性。最近，我们开发了一套利用相互作用结构保守模式的信息来准确预测全基因组蛋白-蛋白相互作用的方法（PrePPI）。我们将进一步发展PrePPI 预测算法，并将其应用于研究更多、更新的蛋白-蛋白互作网络。对某些特殊物种和系统，我们将通过合作发展结合实验信息（比如电镜数据）进行结构模型优化的新方法，以最终揭示蛋白互作 网络在不同环境下的动态变化的进化规律，以及超复合物的精细结构。', ' 类似的结构建模预测方法可以应用于蛋白-RNA 相互作用预测，来提高准确度和灵敏度。难点在于我们对蛋白-RNA 相互作用的知识非常有限。我们参与发展了使用寡核苷酸瓦片阵列和蛋白质谱，来检测与特定 RNA 相互作用蛋白的实验技术（ChIRP-MS）。与流行的CLIP实验以蛋白为中心的研究方法不同，ChIRP以RNA为中心， 可以用来深入研究对某些具有重要功能或者在疾病中异常的 RNA（特别是ncRNA）的相互作用及功能。']
      },
      {
        title: '3、长非编码RNA系统进化和功能分类',
        content: ['越来越多的功能研究发现长链非编码RNA（lncRNA：long noncoding RNA）与很多生物学过程紧密相关，包括参与表观遗传调控过程。特别的，近年一些研究发现lncRNA的异常表达，错误剪切甚至单碱基突变与人类疾病，包括癌症息息相关。物种间序列的比较及进化分析有助于理解序列-功能关系，包括识别对lncRNA功能有意义的序列区段或序列特征，鉴定物种间行使相同功能的同源lncRNA，甚至分组具有相似功能机制的lncRNA等。但由于lncRNA低水平的序列保守性，以及不同物种间不完整的注释信息，对lncRNA进行系统的进化与功能研究非常困难。',
        '我们在前期工作基于进化共线性和序列中基序（motif）的分布格局成功地鉴定了一种低序列一致性的lncRNA在果蝇类群中的直系同源体roX。实验室将发展这一分析思路，基于基因组比较分析包括更多的lncRNA特征，并结合机器学习以及进化分析等手段，系统地研究脊椎动物类群中lncRNA的进化，鉴定一批潜在的直系同源lncRNA组。我们的研究旨在建立一套相对完善的lncRNA分析流程，并为理解脊椎动物lncRNA的进化，尤其是序列中的保守原件及其可能的功能意义提供新的线索。']
      },
      {
        title: '4、RNA病毒分子机制',
        content: ['RNA病毒以RNA为遗传物质。因为缺乏复制过程中的错误修正机制， RNA病毒具有比DNA病毒更高的变异性 。RNA病毒包括很多的常见的传染病致病病毒，如流感（flu）病毒，艾滋病（HIV）病毒，SARS 病毒，MERS病毒，埃博拉病毒（EBV）和大多数植物病毒等。这些病毒的肆虐给人类社会带来巨大和惨痛的生命和经济损失。我们实验室将结合RNA和蛋白的结构和分子相互作用网络，研究RNA病毒入侵细胞以及引起的传染病的分子机制和可能的治疗手段。']
      },
      {
        title: '5、复杂疾病和精准医疗',
        content: ['越来越多的证据表明，具有结构信息的蛋白-蛋白相互作用网络可以帮助我们从更精确程度上理解复杂疾病的机理。由于具有了结构信息，我们可以在更高的解析度上预测不同基因突变带来 的不同后果。对比数量有限的PDB蛋白复合物，我们通过结构建模方法建立的蛋白-蛋白和蛋白-RNA全局相互作用网络将大大提高我们找出那些影响蛋白质的结构和相互作用的基因突变能力。特别的是，癌症基因组的研究发现了数以百万计的可能会癌症相关的突变位点。我们的分析显示，即使这些研究大部分使用侧重于蛋白质编码区的外显子测序，仍然有超过四分之一的突变或者是在非编码区或者不会带来编码的变化。这些在癌症基因组中反复出现的突变无法从蛋白的层次理解，但我们的研究表明，它们可能会通过改变RNA结构进而扰动蛋白-RNA相互作用网络而导致疾病。']
      }
    ]
  },
  team: {
    currentTeam: {
      leaders: {
        PI: {
          img: require('../assets/team/PI.png'),
          title: 'PI',
          simpTitle: 'PI',
          content: [{
            name: '张强锋',
            img: require('../assets/team/zhangqiangfeng.png'),
            pos: '博士  实验室研究员',
            disc: ['清华大学生命学院、清华－北大生命联合中心，研究员、博导', '2000 中国科学技术大学 少年班 学士', '2006 中国科学技术大学 计算机 博士','2012  哥伦比亚大学 生物物理 博士'],
            email: 'qczhang@tsinghua.edu.cn'
          }]
        },
        zhuli: {
          img: require('../assets/team/zhuli.png'),
          title: '助理',
          simpTitle: '助理',
          content: [{
            name: '陈海荣',
            img: require('../assets/team/hairong.jpg'),
            pos: '实验室主管',
            disc: ['日常事务管理'],
            email: 'chenhair@mail.tsinghua.edu.cn'
          }]
        }
      },
      leaders2: {
        boshihou: {
          img: require('../assets/team/boshihou.png'),
          title: '博士后',
          simpTitle: '博士后',
          content: [
            {
              img: require('../assets/team/boshihou/wangqianwen.png'),
              name: '王茜雯  博士',
              poser: '', //'清华大学生命学院博士后',
              disc: ['[1] An ultra low-input method for global RNA structure probing uncovers Regnase-1-mediated regulation in macrophages;','[2] RNA structure probing uncovers RNA structure-dependent biological functions;']
            },
            {
              img: require('../assets/team/boshihou/feiyuhan.jpg'),
              name: '费宇涵 博士',
              poser: '', //'清华大学生命学院博士后',
              disc: ['[1] RNA Structural Dynamics Modulate EGFR-TKI Resistance Through Controlling YRDC Translation in NSCLC Cells', '[2] Advances and opportunities in RNA structure experimental determination and computational modeling;']
            },
            {
              img: require('../assets/team/doctor/zhangjinsong.png'),
              name: '张劲松 博士',
              pos: '', //'博士生在读',
              disc: ['[1] Advances and opportunities in RNA structure experimental determination and computational modeling;', '[2] An ultra low-input method for global RNA structure probing uncovers Regnase-1-mediated regulation in macrophages;','[3] RNA structure probing reveals the structural basis of Dicer binding and cleavage;','[4] RNA structural dynamics regulate early embryogenesis through controlling transcriptome fate and function;']
            },
            {
              img: require('../assets/team/doctor/xukui.png'),
              name: '徐魁 博士',
              pos: '', //'博士生在读',
              disc: ['[1] PrismNet: predicting protein–RNA interaction using in vivo RNA structural information;','[2] Structural basis of membrane skeleton organization in red blood cells;','[3] CryoRes: Local Resolution Estimation of Cryo-EM Density Maps by Deep Learning;','[4] A deep learning method for recovering missing signals in transcriptome-wide RNA structure profiles from probing experiments;','[5] In vivo structural characterization of the SARS-CoV-2 RNA genome identifies host proteins vulnerable to repurposed drugs;','[6] Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures;','[7] Structure of the activated human minor spliceosome;','[8] RASP: an atlas of transcriptome-wide RNA secondary structure probing data;','[9] VRmol: an Integrative Web-Based Virtual Reality System to Explore Macromolecular Structure;','[10] SCALE method for single-cell ATAC-seq analysis via latent feature extraction;','[11] A²-Net: Molecular Structure Estimation from Cryo-EM Density Volumes;','[12] RISE: a database of RNA interactome from sequencing experiments;']
            },
            {
              img: require('../assets/team/doctor/huangwenze.png'),
              name: '黄文泽 博士',
              pos: '', //'博士生在读',
              disc: ['[1] PrismNet: predicting protein–RNA interaction using in vivo RNA structural information;','[2] Comparison of viral RNA–host protein interactomes across pathogenic RNA viruses informs rapid antiviral drug discovery for SARS-CoV-2;','[3] In vivo structural characterization of the SARS-CoV-2 RNA genome identifies host proteins vulnerable to repurposed drugs;','[4] Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures;','[5] RNA structure maps across mammalian cellular compartments;','[6] Integrative Analysis of Zika Virus Genome RNA Structure Reveals Critical Determinants of Viral Infectivity;']
            },
            {
              img: require('../assets/team/doctor/tanglei.png'),
              name: '唐磊',
              pos: '', //'博士生在读',
              disc: ['[1] An ultra low-input method for global RNA structure probing uncovers Regnase-1-mediated regulation in macrophages;','[2] Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures;','[3] SCALE method for single-cell ATAC-seq analysis via latent feature extraction;','[4] RNA structure maps across mammalian cellular compartments;','[5] Integrative Analysis of Zika Virus Genome RNA Structure Reveals Critical Determinants of Viral Infectivity;']
            }

          ]
        },
        doctor: {
          img: require('../assets/team/doctor.png'),
          title: '研究生',
          simpTitle: '研究生',
          content: [
            {
              img: require('../assets/team/doctor/dongzhuoer.jpeg'),
              name: '董卓尔',
              pos: '', //'博士生在读',
              disc: ['[1] CryoRes: Local Resolution Estimation of Cryo-EM Density Maps by Deep Learning;']
            },
            {
              img: require('../assets/team/benke/xujingle.png'),
              name: '徐静乐',
              pos: '', //'博士生在读',
              disc: ['[1] VRmol: an Integrative Web-Based Virtual Reality System to Explore Macromolecular Structure;']
            },
            {
              img: require('../assets/team/doctor/lizegang.jpg'),
              name: '李泽刚',
              pos: '', //'博士生在读',
              disc: ['深度学习 并行计算']
            },
            {
              img: require('../assets/team/doctor/zhujianghui.jpg'),
              name: '朱江徽',
              pos: '', //'博士生在读',
              disc: ['[1] PrismNet: predicting protein–RNA interaction using in vivo RNA structural information;','[2] Recent advances in RNA structurome;','[3] An ultra low-input method for global RNA structure probing uncovers Regnase-1-mediated regulation in macrophages;']
            },
            {
              img: require('../assets/team/doctor/wangpengfei.jpg'),
              name: '王鹏飞',
              pos: '', //'博士生在读',
              disc: ['靶向RNA的小分子药物发现']
            },
            {
              img: require('../assets/team/doctor/daimuzhi.jpg'),
              name: '代沐芷',
              pos: '', //'博士生在读',
              disc: ['[1] CryoRes: Local Resolution Estimation of Cryo-EM Density Maps by Deep Learning;']
            },
            {
              img: require('../assets/team/doctor/zhangjiasheng.jpg'),
              name: '张佳胜',
              pos: '', //'博士生在读',
              disc: ['靶向RNA的小分子药物发现']
            },
            {
              img: require('../assets/team/doctor/ningweixi.jpg'),
              name: '宁微希',
              pos: '', //'博士生在读',
              disc: ['[1] Online single-cell data integration through projecting heterogeneous datasets into a common cell-embedding space;']
            },
            {
              img: require('../assets/team/doctor/majianbo.jpg'),
              name: '马剑博',
              pos: '', //'博士生在读',
              disc: ['RNA设计']
            },
            {
              img: require('../assets/team/doctor/lizihan.jpeg'),
              name: '李梓晗',
              pos: '博士生在读',
              disc: ['靶向RNA药物发现']
            },
            {
              img: require('../assets/team/doctor/moukunting.jpeg'),
              name: '牟坤汀',
              pos: '博士生在读',
              disc: ['深度学习, RNA结构']
            },
            {
              img: require('../assets/team/doctor/caizilin.jpeg'),
              name: '蔡子霖',
              pos: '博士生在读',
              disc: ['RNA设计']
            },
            {
              img: require('../assets/team/doctor/shanxinyue.jpg'),
              name: '单心月',
              pos: '博士生在读',
              disc: ['RNA结构']
            },
            {
              img: require('../assets/team/doctor/fuweining.jpg'),
              name: '傅唯宁',
              pos: '博士生在读',
              disc: ['深度学习, 冷冻电镜']
            }
          ]
        },
      }
    },
    oldTeam: {
      manager: {
        title: '前助理',
        content: [
          {
            img: require('../assets/team/wangrui.png'),
            name: '王蕊',
            pos: '实验室主管',
            disc: ['实验室建立、日常事务管理', 'email: wangrui@phalab.org']
          },
          {
            img: require('../assets/team/weiwendi.png'),
            name: '魏文迪',
            pos: '实验室主管',
            disc: ['实验室建立、日常事务管理', 'email: weiwendi@tsinghua.edu.cn']
          }
        ]
      },
      postdoc: {
        title: '博士后',
        content: [
          {
            img: require('../assets/team/boshihou/xiong.png'),
            name: '熊团林 博士',
            pos: '博士后出站', //'清华大学生命学院博士后',
            disc: ['非编码RNA进化', '出站时间：2021-08']
          },
          {
            img: require('../assets/team/boshihou/zhangshaojun.png'),
            name: '张少军 博士',
            pos: '博士后出站', //'清华大学生命学院博士后',
            disc: ['RNA－蛋白相互作用', '出站时间：2022-08']
          },
          {
            img: require('../assets/team/boshihou/zhaokang.jpg'),
            name: '赵康 博士',
            pos: '博士后出站', //'清华大学生命学院博士后',
            disc: ['RNA结构探测及相关功能分析', '出站时间：2022-10']
          },
          {
            img: require('../assets/team/doctor/sunlei.png'),
            name: '孙磊 博士',
            pos: '博士后出站',
            disc: ['RNA结构探测及相关功能分析', '出站时间：2022-11']
          },
          {
            img: require('../assets/team/boshihou/guofengmin.jpg'),
            name: '郭丰敏 博士',
            pos: '博士后出站', //'清华大学生命学院博士后',
            disc: ['RNA结构探测试剂研究', '出站时间：2023-12']
          },
        ]
      },
      graduate: {
        title: '博士毕业生',
        content: [
          {
            img: require('../assets/team/doctor/sunlei.png'),
            name: '孙磊',
            pos: '博士毕业',
            disc: ['RNA结构探测及相关功能分析', '毕业时间：2019-10']
          },
          {
            img: require('../assets/team/doctor/piaomeiling.png'),
            name: '朴美玲',
            pos: '博士毕业',
            disc: ['RNA结构探测及相关功能分析', '毕业时间：2020-10']
          },
          {
            img: require('../assets/team/doctor/gongjing.png'),
            name: '龚警',
            pos: '博士毕业',
            disc: ['RNA结构和相互作用', '毕业时间：2020-10']
          },
          {
            img: require('../assets/team/doctor/xionglei.png'),
            name: '熊磊',
            pos: '博士毕业',
            disc: ['单细胞', '毕业时间：2020-10']
          },
          {
            img: require('../assets/team/doctor/zhangjinsong.png'),
            name: '张劲松',
            pos: '博士毕业', //'博士生在读',
            disc: ['RNA结构探测及相关功能分析', '毕业时间：2021-06']
          },
          {
            img: require('../assets/team/doctor/xukui.png'),
            name: '徐魁',
            pos: '博士毕业', //'博士生在读',
            disc: ['机器学习、三维图像建模', '毕业时间：2021-06']
          },
          {
            img: require('../assets/team/doctor/lipan.png'),
            name: '李盼',
            pos: '博士毕业', //'博士生在读',
            disc: ['基因组数据分析、算法设计', '毕业时间：2021-06']
          },
          {
            img: require('../assets/team/doctor/huangwenze.png'),
            name: '黄文泽',
            pos: '博士毕业', //'博士生在读',
            disc: ['非编码RNA进化', '毕业时间：2021-10']
          },
          {
            img: require('../assets/team/doctor/weiyifan.png'),
            name: '魏逸凡',
            pos: '博士毕业', //'博士生在读',
            disc: ['RNA文库构建和病毒RNA研究', '毕业时间：2022-06']
          },
          {
            img: require('../assets/team/doctor/tanglei.png'),
            name: '唐磊',
            pos: '博士毕业', //'博士生在读',
            disc: ['RNA相互作用及其功能分析','单细胞', '毕业时间：2022-06']
          },
          {
            img: require('../assets/team/doctor/shaoqiuyan.png'),
            name: '邵燕秋',
            pos: '博士毕业', //'博士生在读',
            disc: ['RNA相互作用及其功能分析', '毕业时间：2022-10']
          },
          {
            img: require('../assets/team/doctor/yangrui.png'),
            name: '杨瑞',
            pos: '博士毕业', //'博士生在读',
            disc: ['病毒RNA结构以及RNA结合蛋白', '毕业时间：2023-12']
          },
          {
            img: require('../assets/team/doctor/tiankang.jpeg'),
            name: '田康',
            pos: '博士毕业', //'博士生在读',
            disc: ['单细胞', '毕业时间：2025-05']
          },
          {
            img: require('../assets/team/doctor/liyuzhe.jpg'),
            name: '李雨哲',
            pos: '博士毕业', //'博士生在读',
            disc: ['单细胞空间转录组学', '毕业时间：2025-05']
          },
          {
            img: require('../assets/team/doctor/huangyong.jpg'),
            name: '黄勇',
            pos: '博士毕业', //'博士生在读',
            disc: ['病毒RNA相互作用；基因组数据分析', '毕业时间：2025-10']
          },
        ]
      },
      assistant: {
        title: '研究助理',
        content: [
          {
            img: require('../assets/team/premember/qinyan.jpg'),
            name: '秦燕',
            pos: '科研助理',
          },
          {
            img: require('../assets/team/premember/liruihua.jpg'),
            name: '李瑞花',
            pos: '科研助理',
          },
          {
            img: require('../assets/team/premember/hange.png'),
            name: '韩鸽',
            pos: '科研助理',
          },
          {
            img: require('../assets/team/doctor/qianxingyang.png'),
            name: '钱兴洋',
            pos: '科研助理',
          },
          {
            img: require('../assets/team/assistant/xuyiran.png'),
            name: '徐艺然',
            pos: '科研助理',
          }
        ]
      },
      benke: {
        title: '本科生',
        content: [
          {
            img: require('../assets/team/benke/wangpengfei.jpg'),
            name: '王鹏飞',
            pos: '本科毕业',
            disc: []
          },
          {
            img: require('../assets/team/benke/zhouxiaolin.jpg'),
            name: '周小琳',
            pos: '本科毕业',
            disc: []
          },
          {
            img: require('../assets/team/benke/dingjiawei.png'),
            name: '丁佳伟',
            pos: '本科毕业',
            disc: []
          },
          {
            img: require('../assets/team/benke/hewanwen.png'),
            name: '何宛雯',
            pos: '本科毕业',
          },
          {
            img: require('../assets/team/benke/nichongming.png'),
            name: '倪崇铭',
            pos: '本科毕业',
          },
          {
            img: require('../assets/team/premember/juyanyan.jpg'),
            name: '巨严严',
            pos: '本科毕业',
            //date: '在职时间：2016-2017',
            //disc: ['现供职于：麻省理工大学']
          },
          {
            img: require('../assets/team/premember/panhanying.jpg'),
            name: '潘汉英',
            pos: '本科毕业',
            //date: '在职时间：2016-2017',
            //disc: ['现供职于：麻省理工大学']
          },
          {
            img: require('../assets/team/premember/liyongge.jpg'),
            name: '李永歌',
            pos: '本科毕业',
            //date: '在职时间：2016-2017',
            //disc: ['现供职于：麻省理工大学']
          },
          {
            img: require('../assets/team/premember/tianyuan.jpg'),
            name: '田原',
            pos: '本科毕业',
            //date: '在职时间：2016-2017',
            //disc: ['现供职于：麻省理工大学']
          },
          {
            img: require('../assets/team/premember/xujialu.jpg'),
            name: '徐家璐',
            pos: '本科毕业',
            //date: '在职时间：2016-2017',
            //disc: ['现供职于：麻省理工大学']
          },
          {
            img: require('../assets/team/premember/lengjingze.png'),
            name: '冷静泽',
            pos: '本科毕业',
            //date: '在职时间：2016-2017',
            //disc: ['现供职于：麻省理工大学']
          },
          {
            img: require('../assets/team/premember/xiaoyi.jpg'),
            name: '肖嫕',
            pos: '本科毕业',
            //date: '在职时间：2016-2017',
            //disc: ['现供职于：麻省理工大学']
          },
          {
            img: require('../assets/team/premember/zhangyn.jpg'),
            name: '张宇宁',
            pos: '本科毕业',
            //date: '在职时间：2016-2017',
            //disc: ['现供职于：麻省理工大学']
          },
          {
            img: require('../assets/team/premember/xujun.png'),
            name: '徐俊',
            pos: '本科毕业',
            //date: '在职时间：2016-2017',
            //disc: ['现供职于：麻省理工大学']
          }
        ]
      },
      visit: {
        title: '访问学生',
        content: [
          {
            img: require('../assets/team/premember/yuanyitian.png'),
            name: '袁义天',
            pos: '访问学生',
            disc: ['Johns Hopkins University']
          },
        ]
      }
    },
    tabData: ['PI', '助理', '博士后', '研究生', '本科生', '科研助理', '前成员']
  },
  home: {
    title: {
      title: '实验室简介'
      // disc: '细心观察、认真分析、科学总结'
    },
    content: [
      {
        disc: '我们实验室隶属于清华大学生命科学学院，同时也是清华大学－北京大学生命联合中心，清华大学结构生物学高精尖创新中心，清华大学教育部生物信息实验室，以及清华大学合成和系统生物学中心的一部分。'
      },
      {
        disc: '我们的研究方向是结构生物学、基因组学、机器学习和大数据分析等多学科综合交叉领域。我们主要的研究兴趣是发展和使用计算和实验相结合的方法，阐释生物大分子（如蛋白、RNA、DNA）结构和功能关系，重建其相互作用网络，以及结合结构生物学和系统生物学的手段，发掘与蛋白及RNA结构变化，以及大分子相互作用网络异常相关的复杂疾病（包括癌症和传染病）的机理和可能的治疗手段。我们拥有独特的蛋白和RNA结构建模和基于下一代测序的RNA结构测量、和高通量RNA－蛋白相互作用检测的技术，以及强大的计算和实验平台来推进我们的研究。'
      },
      {
        disc: ['我们的主要研究兴趣有:', '1，解析生物大分子结构，特别是使用高通量深度测序的手段来探测和使用机器学习方法预测和建模RNA二级结构；并在此基础上阐释结构和功能关系，特别是发展蛋白质功能位点，RNA功能模体（motif）等有效预测或发现的机器学习方法；','2，发展基于高通量实验和结构建模的方法重建生物大分子如蛋白-蛋白、RNA-RNA、以及蛋白-RNA相互作用网络；', '3，特别的，针对长非编码RNA（lncRNA），结合序列和结构分析研究其系统进化和功能分类；', '4，结合RNA和蛋白的结构和分子相互作用网络，研究RNA病毒入侵细胞以及引起的传染病的分子机制和可能的治疗手段；', '5，发掘基因组突变导致蛋白及RNA折叠错误以及互作网络失调而引起疾病的机理，以及结合结构和网络发展对复杂疾病包括癌症的诊断、预后以及可能的治疗手段。']
      }
    ]
  },
  news: ['新闻', '活动'],
  contact: {
    title: 'CONTACT US',
    info: [
      ['实验室地址：北京市清华大学生物医学馆 A-109', '实验室邮编：100084', '实验室电话：86-10-627-92786'],
      ['办公室地址：北京市清华大学生物医学馆 A-116', '办公室邮编：100084', '办公室电话：86-627-95823', 'Email： qczhang@tsinghua.edu.cn'],
      ['助理：陈海荣', 'Email： chenhair@mail.tsinghua.edu.cn']
    ],
    links: {
      title: '友情链接',
      content: [
        {
          title: '生命科学联合中心',
          url: 'http://www.cls.edu.cn/'
        },
        {
          title: '清华大学生命科学学院',
          url: 'http://life.tsinghua.edu.cn/'
        },
        {
          title: '结构生物学高精尖创新中心',
          url: 'http://www.icsb.tsinghua.edu.cn/'
        }
      ]
    }
  },
  xiuxian: ['2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '生日会'],
  resource: ['在线服务', 'Protocol', '已发表数据', '内部wiki']
}

export default cn
