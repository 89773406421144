import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    newData: {},
    activity: [],
    currentPage: 1
  },
  getters: {
    newData: state => {
      return state.newData
    },
    activity: state => {
      return state.activity
    },
    currentPage: state => {
      return state.currentPage
    }
  },
  mutations: {
    newData: (state, payload) => {
      state.newData = payload
    },
    activity: (state, payload) => {
      state.activity = payload
    },
    currentPage: (state, payload) => {
      state.currentPage = payload
    },
  }
})
