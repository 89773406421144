<template>
    <nav class="header-container" id="header">
        <img src="../assets/index/logo-en.png"  v-if="_i18n.locale === 'en'" class="logo" alt="logo" @click="goHome">
        <img src="../assets/index/logo.png" v-if="_i18n.locale === 'cn'" class="logo" alt="logo" @click="goHome">
        <div class="menu-container">
          <p v-for="(item, index) in $t('headerData')" :class="['menu-item', {'smaller': locale === 'en'}]" :key="item.title" @click="handleClick(index, item.url)">
            <span :class="[{active: (index === activeIndex && hoverIndex !== index)}]">
                {{ item.title }}
            </span>
          </p>
        </div>
        <div class="right-box" @click="changelang">
            <img src="../assets/index/translate.png" class="translate" alt="translate">
        </div>
    </nav>
</template>

<script>
export default {
  name: 'dheader',
  data () {
    return {
      headerData: this.$i18n.headerData,
      activeIndex: parseInt(this.$route.query.index) || '',
      status: false,
      hoverIndex: null,
      locale: this.$i18n.locale
    }
  },
  watch: {
    '$route.query.index' () {
      this.activeIndex = parseInt(this.$route.query.index)
    }
  },
  methods: {
    handleClick (index, url) {
      this.activeIndex = index
      this.$router.push(`${url}?index=${index}`)
    },
    changelang () {
      if (this.$i18n.locale === 'cn') {
        this.$i18n.locale = 'en'
        this.locale = 'en'
      } else {
        this.$i18n.locale = 'cn'
        this.locale = 'cn'
      }
    },
    goHome () {
      this.$router.push('/')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.header-container {
  width: 100%;
  background: #FFFFFF;
  /*box-shadow: 0 2px 20px 0 rgba(199,199,199,0.50);*/
  /*padding: 0 15%;*/
  padding: 0 4rem;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  position: fixed;
  z-index: 999;
  top: 0;
  height: 10.6rem;
  min-width: 900px;

    .logo {
        height: 5.5rem;
        display: inline-block;
        vertical-align: middle;
        align-self: center;
    }

    .right-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    div {
        display: inline-block;
        box-sizing: border-box;
    }

    .menu-container {
        width: 60%;
        text-align: right;
        font-size: 18px;
        color: #949494;
        letter-spacing: 0.92px;
        max-width: 1200px;
        min-width: 568px;
        margin: 0 auto;
        vertical-align: middle;

        .menu-item {
            display: inline-block;
            vertical-align: top;
            /*width: 14.2%;*/
            width: 12.5%;
            cursor: pointer;
            line-height: 10.6rem;
            box-sizing: border-box;
            background: #fff;
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;
            margin: 0;

            .child-item {
                height: 50px;
                line-height: 50px;
                font-size: 14px;
                /*border-radius: 10px;*/
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            .child-item:hover {
                background: #f3f3f3;
            }
        }

        .smaller {
          font-size: 1.2rem;
        }

        .border-radio {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        .active {
            padding: 0 0 1rem 0;
            color: #000;
            font-weight: 600;
            border-bottom: 0.3rem solid #000;
        }
    }

    .menu-btn {
        width: 20%;
        display: inline-block;
        vertical-align: top;
        text-align: left;

        img {
            width: auto;
            height: 43px;
            vertical-align: middle;
        }
    }

    .menu-item:hover {
        background: #F9F9F9;
        /*border-bottom: 1px solid #f4f4f4;*/
    }
}
</style>
