<template>
  <div class="activities">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContentBox'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .activities {
    background: #f5f5f5;
    padding-bottom: 68px;
    padding-top: 1.66rem;

    .content {
      width: 80%;
      margin: 0 auto;
      max-width: 1200px;
      min-width: 900px;
      text-align: left;
      overflow: hidden;
    }
  }
</style>
