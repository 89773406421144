import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import language from './language'
import VueI18n from 'vue-i18n'
import 'element-ui/lib/theme-chalk/index.css';
import { Notification, Tooltip } from 'element-ui';
import BaiduMap from 'vue-baidu-map'
import waterfall from 'vue-waterfall2'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Vue.use(waterfall)

Vue.prototype.$notify = Notification;
Vue.use(Tooltip);

Vue.use(VueLazyload)
Vue.use(VueI18n)

Vue.use(BaiduMap, {
  ak: 'F0sU3DnnLrSFwKcWIkU7BftfRxowx5Ti' // 我使用的是我的密钥，你们可以自己去申请一个密钥, http://lbsyun.baidu.com/apiconsole/key
})

Vue.config.productionTip = false

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages: language // set locale messages
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
